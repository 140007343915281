<template>
    <v-container>
        <v-row no-gutters>
            <v-col cols="12">
                <MyFilter :filter="filter" v-if="Object.keys(filter).length" />
                <Filters height="80vh" v-else style="overflow-y: scroll;" />
                <!-- style="height: 80vh;" -->
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from "@vue/composition-api";
import { useRoute } from "vue2-helpers/vue-router";

import MyFilter from "@/components/Filter.vue";
import Filters from "@/components/Filters.vue";

import axios from "axios";

export default {
    props: {},
    name: "FiltersView",
    emits: ["breadcrumb"],
    components: {
        MyFilter,
        Filters,
    },
    setup() {
        const route = useRoute();
        const { emit } = getCurrentInstance();

        const filter = ref({});
        const newfilter = ref(false);

        onMounted(() => {
            emit("breadcrumb", [
                {
                    text: "Filters",
                    disabled: false,
                    href: "/filters",
                },
            ]);

            if (route.params.filter_guid) {
                axios.get(`/api/v1/filters/${route.params.filter_guid}`).then((response) => {
                    filter.value = response.data;

                    emit("breadcrumb", [
                        {
                            text: "Filters",
                            disabled: false,
                            href: "/filters",
                        },
                        {
                            text: filter.value.name,
                            disabled: false,
                            href: `/filters/${route.params.filter_guid}`,
                        },
                    ]);
                });
            }
        });

        return {
            filter,
            newfilter,
        };
    },
};
</script>
