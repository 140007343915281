<template>
    <MyCard :height="height" :width="width">
        <template v-slot:header>
            Filter {{ filter.name }}
            <v-spacer />
            <IPEntryAdd :filter="filter" @newIPEntry="add_entry($event)" />
        </template>
        <v-data-table
            dense
            class="row-pointer"
            :items="filter.ip_entries"
            :headers="headers"
            :hide-default-footer="filter.ip_entries.length < 11"
            rounded
            background-color="transparent"
            no-data-text="With no entries the filter won't do much. Add some by clicking the New button."
        >
            <template #item.remove="{ item }">
                <IPEntryDelete :entry="item" />
            </template>
        </v-data-table>
    </MyCard>
</template>

<script>
import IPEntryAdd from "@/components/IPEntryAdd.vue";
import IPEntryDelete from "@/components/IPEntryDelete.vue";
import MyCard from "@/components/MyCard.vue";

// import { ref, onMounted } from "@vue/composition-api";
// import { useRouter } from "vue2-helpers/vue-router";

export default {
    props: ["height", "width", "filter"],
    name: "MyFilter",
    components: {
        MyCard,
        IPEntryAdd,
        IPEntryDelete,
    },
    setup(props) {
        const headers = [
            { text: "IP/Subnet", value: "prefix", show: true, width: "20%" },
            { text: "IP version", value: "version", show: true, width: "20%" },
            { text: "Description", value: "description", show: true, width: "20%" },
            { text: "Delete", value: "remove", width: "5%", align: "center" },
        ];

        function add_entry(newEntry) {
            props.filter.ip_entries.push(newEntry);
        }

        return {
            headers,
            add_entry,
        };
    },
};
</script>
