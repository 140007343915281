<template>
    <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on }">
            <ButtonNew :action="on.click" />
        </template>
        <v-card class="rounded-lg">
            <v-card-title class="text-h6 primary">Add a new IP entry</v-card-title>
            <v-card-text>
                <v-text-field v-model="entry" label="IP address/network" @keyup.native.enter="add" @input="autodetectVersion" autofocus />
                <v-text-field v-model="description" label="Description" @keyup.native.enter="add" @keyup="keyup($event)" />
                <v-select :items="[4, 6]" v-model="version" label="IP version"></v-select> 
            </v-card-text>

            <v-divider />
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn width="50%" rounded color="green" text @click="add" :disabled="addDisabled">Add</v-btn>
                <v-btn width="50%" rounded color="red" text @click="close">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ButtonNew from "@/components/ButtonNew.vue";
import { ref, getCurrentInstance } from "@vue/composition-api";
import axios from "axios";

export default {
    name: "IPEntryAdd",
    props: ["filter"],
    components: {
        ButtonNew,
    },
    setup(props) {
        const { emit } = getCurrentInstance();

        const dialog = ref(false);

        const errorMessages = ref("");
        const successMessages = ref("");
        const addDisabled = ref(false);

        const entry = ref("");
        const description = ref("");
        const version = ref(4);

        function keyup(event) {
            if (event.key === "Enter") {
                add();
                return;
            }
        }

        function add() {
            addDisabled.value = true;

            const postData = {
                entry: entry.value,
                description: description.value,
                version: version.value,
                filter_guid: props.filter.guid,
            };

            axios
                .post(`/api/v1/ip_entry`, postData)
                .then((response) => {
                    if (response.data.error) {
                        // entry exists
                        close();
                        return;
                    }
                    successMessages.value = "Network added successfully!";
                    emit("newIPEntry", response.data);
                    setTimeout(() => {
                        close();
                    }, 1000);
                })
                .catch((error) => {
                    if (error.response.status == 405) {
                        addDisabled.value = false;
                    }
                });
        }

        function autodetectVersion() {
            version.value = entry.value.includes(":") ? 6 : 4;
        }

        function close() {
            dialog.value = false;
            errorMessages.value = "";
            successMessages.value = "";
            addDisabled.value = false;
            entry.value = "";
            description.value = "";
            version.value = 4;
        }

        return {
            dialog,
            errorMessages,
            successMessages,
            addDisabled,
            entry,
            description,
            version,
            keyup,
            add,
            close,
            autodetectVersion,
        };
    },
};
</script>
